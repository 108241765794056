.btn {
  border-radius: 0;
  margin: 10px 0;
}
.btn-lg {
  padding: 17px 12px;
  font-size: 18px;
  min-width: 210px;
}

// Separator
.separator {
width: 100px;
background-color: #434343;
height: 1px;
text-align: center;
margin: 25px auto;
position: relative;
}
.separator:after {
position: absolute;
width: 8px;
height: 8px;
background-color: #00c4c5;
content: "";
top: -4px;
margin-left: -4px;
left: 50%;
}

// Call to action
.call-to-action {
  width: 100vw;
  margin-left: -15px;
  padding: 25px 0;
  background-color: #00c4c5;
  color: white;
  margin-bottom: 20px;
  h2 {
      font-size: 28px;
      font-weight: 200;
  }

  .btn {
      color: white;
      border-color: white;
  }
}

// Icon box
.icon-box {
  display: flex;
  flex-direction: row;
  i {
      flex-shrink: 0;
      border: 1px solid #00c4c5;
      font-size: 24px;
      width: 55px;
      height: 55px;
      text-align: center;
      line-height: 56px;
      color: #00c4c5;
  }
  &:hover i {
      background-color: #00c4c5;
      border-color: #00b5b5;
      color: white;
  }
  .text {
      padding-left: 50px;
      // margin-left: 100px;
  }
}

// Mask
.mask-wrapper {
  &:hover + .mask {
      opacity: 1;
  }
  + .mask {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
      text-align: center;
      opacity: 0;
  }
}

// Triangle list
ul.list-triangle {
  list-style: none;
  padding: 0;
  margin: 20px 0;

  li {
      padding-left: 1.3em;
  }

  li:before {
      font-family: "FontAwesome";
      content: "\f0da";
      display: inline-block;
      margin-left: -1.3em;
      color: #00c4c5;
      width: 1.3em;
  }
}

.section {
  padding: 65px 0 45px;
}

// Bootstrap Carousel
.carousel-caption {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  position: absolute;
  left: 0;
  
}