.btn {
	padding: 10px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	min-width: 160px;
	text-align: center;
	border-radius: 0;
	text-transform: uppercase;
	margin: 10px 0;
	transition: all 0.2s ease-in-out;
}
.btn.btn-lg {
	padding: 17px 12px;
	font-size: 18px;
	min-width: 210px;
}
.btn-default {
	background-color: #00c4c5;
	color: #fff;
	border-color: #00c4c5;
}
.btn-default:hover, 
.btn-default:focus, 
.btn-default:active {
	color: #fff;
	background-color: #3472a8;
	border-color: #3472a8;
}
.btn-white {
	background-color: transparent;
	color: #262626;
	border: 1px solid #b2bfca;
}
.btn-white:hover, 
.btn-white:focus, 
.btn-white:active {
	color: #fff;
	background-color: #3472a8;
	border-color: #3472a8;
}
.bg-color-default .btn-white {
	color: #fff;
	border-color: #fff;
}
.bg-color-default .btn-white:hover, 
.bg-color-default .btn-white:focus, 
.bg-color-default .btn-white:active {
	color: #fff;
	background-color: #3472a8;
	border-color: #3472a8;
}

.btn-default {
	background-color: #00c4c5;
	border-color: #00c4c5;
}
.btn-default:hover, 
.btn-default:focus, 
.btn-default:active {
	background-color: #00b5b5;
	border-color: #00b5b5;
}
.btn-white:hover, 
.btn-white:focus, 
.btn-white:active {
	background-color: #00b5b5;
	border-color: #00b5b5;
}
.bg-color-default .btn-white:hover, 
.bg-color-default .btn-white:focus, 
.bg-color-default .btn-white:active {
	background-color: #00b5b5;
	border-color: #00b5b5;
}