$footer-height: 275px;

html {
	/* Background for overscroll - should match nav color */
    background: white;
    border-top: 1px solid #00c4c5;
}

body {
	min-height: 100vh;
	position: relative;
	margin: 0;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	font-size: 15px;
	line-height: 1.50;
	color: #434343;
	background-color: white;
    padding-bottom: $footer-height;
    @media (max-width: 768px) {
        padding-bottom: 400px;
    }
    @media (max-width: 576px) {
        padding-bottom: 500px;
    }
}

header {
    border-bottom: 1px solid rgb(224, 224, 224);
    background-color: rgb(248, 248, 248);
    ul {
        list-style-type: none;
    }

    .navbar-nav {
        margin-left: auto;
        font-size: 16px;

        li a {
            padding: 5px 20px;
            text-decoration: none;
            color: #434343;
            &:hover {
                background-color: #00c4c5;
                color: white;
            }
        }

        li {
            &.active a {
                background-color: #00c4c5;
                color: white;
            }

            &:has(ul)::after {
                content: "\25AE";
                font-family: FontAwesome;
            }

            ul.sub-menu {
                display: none;
                position: absolute;
                z-index: 1;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                background-color: #f9f9f9;
                padding: 0;
                
                a {
                    padding: 5px 20px;
                }
            }

            &:hover .sub-menu {
                display: block;
            }
        }
    }
}

footer {
	position: absolute;
	height: $footer-height;
	right: 0;
	left: 0;
	bottom: 0;
	border-top: 0 solid transparent;
	border-top-width: 1px;
	background: #2e343a;
    padding: 10px 0 0;
    padding-bottom: 44px;
    border-top-width: 1px;
    color: #999;
    line-height: 21px;

    @media (max-width: 768px) {
        height: 400px;
    }
    @media (max-width: 576px) {
        height: 500px;
    }

    h2 {
        color: #cecece
    }

    ul {
        list-style-type: none;

        &.social-links {
            font-size: 0;
            padding: 0;
            margin: 40px 0 0 0;

            li {
                display: inline-block;

                a {
                    background-color: transparent;
                    color: #cecece;
                    width: 45px;
                    height: 45px;
                    border: 1px solid #cecece;
                    display: block;
                    text-align: center;
                    font-size: 20px;
                    margin: 0 7px 7px 0;

                    i {
                        line-height: 44px;
                    }
                }
            }
        }
    }

	a {
    	color: #fff;
	}
	a, a:hover {
    	text-decoration: none !important;
    }
    
    ul.partners {
        background-color: white;
    }
}

.header-top {
	border-bottom: 1px solid #e0e0e0;
	color: #464646;
	padding: 7px 0 0 0;

    ul {
        margin-left: auto;
        list-style-type: none;

        li {
            display: inline-block;
            // margin-right: 7px;
            &:not(:first-child) {
                margin-left: 7px;
            }

            i {
                color: #00c4c5
            }
        }
    }
}

.page-intro {
    min-height: 90px;
    margin: 0 0 20px;
    border-bottom: 1px solid #e7e7e7;
    box-shadow: inset 0px -2px 2px #ececec;
    background-color: #fcfcfc;
    width: 100vw;
    margin-left: -15px;

    h1 {
        margin: 25px 0 10px;
    }
}

p {
    margin: 20px 0;
}

ul {
    list-style-type: none;
    &.info li {
        padding-bottom: 10px;
    }
}