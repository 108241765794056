form {
	margin: 20px 0;
}
.form-control {
	border-radius: 0;
	box-shadow: none;
	background: transparent;
	height: 40px;
	padding-right: 30px;
	border-color: #b2bfca;
	color: #000;
	position: relative;
	
}
.form-control:focus {
	border-color: #727272;
	
	box-shadow: none;
}
.form-group {
	position: relative;
}

/* Validation messages/forms
----------------------------------*/
.form-group.has-error:after {
	color: #a94442;
}
.form-group.has-error span {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	color: #a94442;
}
.form-group label {
	font-weight: 400;
}

input, input[type="text"], input[type="date"], input[type="email"], input[type="tel"] {
    outline: 0;
    border-radius: 0;
    box-shadow: none;
}

input[type=email],
input[type=tel],
input[type=text],
textarea {
    width: 100%;
    border: solid 1px #e3e3e3;
    border-bottom-color: #F0F0F0;
    border-top-color: #e0e0e0;
    color: #777;
    background-color: rgba(0,0,0,.03) !important;
    border-radius: 1px;
    transition: all .2s linear;
    outline: none !important;
    box-shadow: 0 1px 2px rgba(10,10,10,.1)inset;
    backface-visibility: hidden;
}

input[type=email]:focus,
input[type=text]:focus,
textarea:focus {
    color: #323436;
    background-color: rgba(150,150,150,.01) !important;
    outline: none !important;
    transition: all .2s linear;
}

.form-control {
    font-size: 13px;
    height: 40px; 
    border: 1px solid #e3e3e3;
    border-radius:2px;
}

.form-control:focus {
    box-shadow: none;
    color: #323436;
    background-color: rgba(250, 250, 250, .01);
    outline: none !important;
    border: solid 1px #e3e3e3;
    border-bottom-color: #F0F0F0;
    border-top-color: #e0e0e0;
    box-shadow: 0 1px 2px rgba(10,10,10,.1)inset;
    transition: all .2s linear;
}

button, input[type="submit"] {
    transition: all 0.3s ease-in-out;
}

.form-control::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #999;
}

.form-control::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
textarea::-moz-placeholder {
    color: #999;
}


.form-group:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.form-control {
	color: black !important;
}